import {
  useContext,
  useReducer,
  createContext,
  useCallback,
  useState,
} from "react";
import { useRequest } from "services/request";
import { userStorage } from "services/userStorage";
import { useToken } from "services/useToken";

const Context = createContext();

export function useProfile() {
  return useContext(Context);
}

const initState = {
  list: [],
  selected: null,
  selectU: {},
  pID: 0,
  status: "idle",
  error: null,
  email: "",
  userLimits: null,
  settings: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_selectU":
      return { ...state, selectU: { ...action.payload } };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "selected_id":
      return { ...state, pID: action.payload };

    case "set_email":
      return { ...state, email: action.payload };
    case "set_limits":
      return { ...state, userLimits: action.payload };
    case "set_settings":
      return { ...state, settings: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function ProfileProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();
  const [em, setEm] = useState("");
  const [proUser, setproUser] = useState({});
    const { 
    state:{userId}
    }=useToken()

  const fetchProfile = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(`user/${userId&&userId}`, null, { method: "GET" }, true);
        dispatch({ type: "set_selected", payload: resData });
        resolve(resData);
      } catch (e) {
        reject(e);
      } finally {
        dispatch({ type: "status", payload: `idle` });
      }
    });
  }, [req]);

  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });

        try {
          const resData = await req(
            "api/profile/change_password/",
            data,
            null,
            {
              current_password: data.current_password,
              new_password: data.new_password,
              confirm_new_password: data.confirm_new_password,
            }
          );

          dispatch({ type: "add", payload: resData });

          resolve(resData);
          console.log(resData, "changePassword");
        } catch (e) {
          console.log(e);
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const update = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `loading` });

          const resData = await req(
            `user/${id}`,
            data,
            { method: "PATCH" },
            true
          );
          setproUser({ ...resData });
          dispatch({ type: "set_selectU", payload: proUser });
          dispatch({ type: "status", payload: `idle` });
          dispatch({ type: "edit", payload: resData });
          console.log(resData);
          resolve(resData);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    [req]
  );

  const remove = useCallback(
    async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `deleting ${id}` });

          const resData = await req(
            `user/${id}`,
            {},
            { method: "DELETE" },
            true
          );

          dispatch({ type: "status", payload: "idle" });
          dispatch({ type: "delete", payload: id });

          resolve(resData);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    [req]
  );

  let editUserInfo = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });

        try {
          const resData = await req(
            `user/${id}`,
            null,
            { method: "GET" },
            true
          );

          setproUser({ ...resData });
          dispatch({ type: "set_selectU", payload: proUser });
          dispatch({ type: "status", payload: `idle` });
          console.log(proUser, "data");
          resolve(proUser);
        } catch (error) {
          reject(error);
          console.log(error);
        }
      });
    },
    [req]
  );

  let setEmail = useCallback((data) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: "status", payload: `fetching` });
      dispatch({ type: "set_email", payload: data });
      dispatch({ type: "status", payload: `idle` });
      setEm(data);
      resolve(data);
    });
  }, []);

  const fetchLimits = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req("user-limits", null, { method: "GET" }, true);
        dispatch({ type: "set_limits", payload: resData });
        resolve(resData);
      } catch (e) {
        reject(e);
      } finally {
        dispatch({ type: "status", payload: `idle` });
      }
    });
  }, [req]);

  const fetchSettings = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req("settings", null, { method: "GET" }, true);
        dispatch({ type: "set_settings", payload: resData });
        resolve(resData);
      } catch (e) {
        reject(e);
      } finally {
        dispatch({ type: "status", payload: `idle` });
      }
    });
  }, [req]);

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        create,
        update,
        fetchProfile,
        editUserInfo,
        proUser,
        em,
        setEmail,
        remove,
        fetchLimits,
        fetchSettings,
      }}
    >
      {children}
    </Context.Provider>
  );
}
