import {
  createContext,
  useContext,
  useCallback,
} from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useRequest } from "./request";
import { useToken } from "./useToken";
import { userStorage } from "./userStorage";
import { decodeToken } from "utilities/decodeToken";

const AuthContext = createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}
 
export default function AuthProvider({ children }) {
  const req = useRequest();
  const { 
  state:{accessToken}
  ,dispatch 
  }=useToken()
  


  let createAccount = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `auth/sign-up`,
            data,
            { method: "POST",credentials: "include" },
            false
          );
          const userRole = decodeToken(resData?.accessToken);
          dispatch({ type: "set_accessToken", payload:resData?.accessToken});
          dispatch({ type: "set_userId", payload:resData?.userId});
          window.localStorage.setItem(
            "user",
            JSON.stringify({
              role:userRole,
              refreshToken: resData.refreshToken,
            })
          );
          resolve(resData);
        } catch (error) {
          reject(error);
          console.log(error);
        }
      });
    },
    [req]
  );
  const verify = useCallback(
    (email, verifyToken) => {
      return new Promise(async (resolve, reject) => {
        try {
          await req(
            `auth/email/verify`,
            { email, verifyToken },
            { method: "POST" },
            false
          );
          resolve();
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    [req]
  );
  let resendVerification = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(
            `auth/email/resend-verification`,
            {"email":data},
            { method: "POST" },
            true
          );
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  let signin = useCallback(
    (data,rememberMe) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(`auth/sign-in`, data,{credentials: "include"});
          const userRole = decodeToken(res?.accessToken);
          dispatch({ type: "set_accessToken", payload:res?.accessToken});
          dispatch({ type: "set_userId", payload:res?.userId});
          const userStorage = rememberMe ? window.localStorage : window.sessionStorage;
          userStorage.setItem(
            "user",
            JSON.stringify({
              role: userRole,
              refreshToken: res.refreshToken,
            })
          );
          resolve(res);
        } catch (error) {
          reject(error);
          console.log("error", error);
        }
      });
    },
    [req]
  );
  let forgotPass = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `auth/forgot-password`,
            data,
            { method: "POST" },
            false
          );
          resolve(resData);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  const resetPass = useCallback(
    (newPassword, token) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `auth/reset-password`,
            { newPassword, token },
            { method: "POST" },
            false
          );
          resolve(resData);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  let changePass = useCallback(
    (currentPassword, newPassword) => {
      return new Promise(async (resolve, reject) => {
        try {
          const data = {
            currentPassword: currentPassword,
            newPassword: newPassword,
          };

          const resData = await req(
            `auth/change-password`,
            data,
            { method: "POST",credentials: "include" },
            true
          );

          resolve(resData);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  let signinByGoogle = useCallback(
    (code) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(
            `google-auth`,
            { code: code },
            { method: "POST",credentials: "include"},
            false
          );
          const userRole = decodeToken(res?.accessToken);
          dispatch({ type: "set_accessToken", payload:res?.accessToken});
          dispatch({ type: "set_userId", payload:res?.userId});
          window.localStorage.setItem(
            "user",
            JSON.stringify({
              role:userRole,
              refreshToken: res.refreshToken,
            })
          );
          resolve(res);
        } catch (error) {
          reject(error);
          console.log("error", error);
        }
      });
    },
    [req]
  );
  let signout = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        if (accessToken) {
        try {
          const res = await req(
            `auth/log-out`,
            {},
            { method: "POST",credentials: "include" },
            true
          );
          resolve(res);
        } catch (error) {
          reject(error);
        }}
        userStorage.removeUser();
        window.location.replace("/login");
      });
    },
    [req,accessToken]
  );

  let value = {
    signin,
    forgotPass,
    signout,
    changePass,
    verify,
    createAccount,
    resendVerification,
    signinByGoogle,
    resetPass,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function RequireAuth({ children }) {
  const location = useLocation();
  const storedUser = userStorage.getUser();
  
  if (!storedUser || storedUser?.role==="Admin"){
    return <Navigate to="/login" state={{ from: location }} replace />;}
  return children;
}

export function RequireAdminAuth({ children }) {
  const location = useLocation();
  const storedUser = userStorage.getUser();
  // console.log(!(storedUser?.role==="Admin"),!storedUser);
  if (!storedUser || !(storedUser?.role==="Admin")){
    return <Navigate to="/adminLogin" state={{ from: location }} replace />;}else{
  return children;}
}
