import { createContext, useContext, useReducer } from "react";

const TokenContext = createContext(null);

export function useToken() {
  return useContext(TokenContext);
}
 
const initState = {
  accessToken:"",
  userId :null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_accessToken":
      return { ...state, accessToken: action.payload };
    case "set_userId":
      return { ...state, userId: action.payload };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};
export default function TokenProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);

let value = {
    state,
    dispatch
  };

  return <TokenContext.Provider value={value}>{children}</TokenContext.Provider>;
}