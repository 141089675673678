import styled from "styled-components";
import { Box, Button, Checkbox, Flex } from "ui";
import { useAdmin } from "../provider";
import { useState } from "react";

const StyledButton = styled(Button)`
  text-transform: uppercase;
  font-size: 12px;
  color: darkBlue;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 3px;
  &:hover,
  &:active ,
  &.active {
    color: white;
    background-color: darkBlue;
    svg path {
      fill: ${(props) => props.theme.color.white};
    }
    svg.export path {
      fill: ${(props) => props.theme.color.darkBlue};
      stroke: ${(props) => props.theme.color.white};
    }
  }
`;

const sortOptions = [
   {
      label: "Data (Newest first)",
      sortKey: "newestFirst",
    },
    {
      label: "Data (Oldest first)",
      sortKey: "oldestFirst",
    },
  ];
  const buttons = [
    {
      name: "Action",
      iconName: "More",
      label: "Action",
    },
    {
      name: "Sort",
      iconName: "Sort",
      label: "Sort",
    }
  ];
function TableHeader ({all,handleCheckAll,checkbox,cols,getCheckedIds,handleButtonClick,activeButton}) {
  const {
    state:{page,limit},
    FetchUsersList,
    deleteUser
  } = useAdmin();
   const [activeSortOption, setActiveSortOption] = useState();

  const handleSort = async (sortKey) => {
    setActiveSortOption(sortKey)
      if (!(sortKey==='spamFirst' || sortKey ==='spamLast')) {
        let order;
        if (sortKey === 'newestFirst'){
          order='desc'
        } else if (sortKey==='oldestFirst') {
          order='asc'
        }
        await FetchUsersList(page, limit ,order);
      }
    };
    const handleDeleteusers = async () => {
      const usersIds = getCheckedIds();
      if(usersIds.length!==0){
        const result = window.confirm("Are you sure you want to delete this user?");
        if(result){
          try {
            await deleteUser( {ids:usersIds});
          } catch (error) {
            
          }
         }
    }
      
    }
    
    return(
    <>
      <tr>
          <td
          colSpan={
              cols.length +
              (checkbox ? 1 : 0)
          }
          >
            <Box sx={{ br: "4px" }}>
                <Flex
                jc="space-between"
                ai="center"
                sx={{ p: "5px" }}
                style={{ backgroundColor: "#f2f3f9" }}
                >
                  <Checkbox
                      size="small"
                      label="select all"
                      id="all"
                      checked={all}
                      inputProps={{
                      checked: all,
                      onChange: handleCheckAll,
                      }}
                      style={{
                      margin: "0px",
                      backgroundColor: "white",
                      color: "darkBlue",
                      padding: "3px",
                      borderRadius: "4px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                      }}
                  />
                  <Flex gap="5">
                      {buttons.map((button) => (
                      <StyledButton
                        key={button.iconName}
                        size="small"
                        iconName={button.iconName}
                        onClick={() => {
                          handleButtonClick(button.name);
                        }}
                        className={
                          activeButton === button.name ? "active" : ""
                        }
                      >
                        {button.label}
                      </StyledButton>
                    ))}
                  </Flex>
                </Flex>
                <Flex
                jc="flex-start"
                gap="5"
                sx={{ p: "5px" }}
                style={{ backgroundColor: "#d7d9ed" }}
                >
                  {activeButton==='Action'?
                  (
                  <StyledButton
                  size="small"
                  iconName={"Xsign"}
                  onClick={() => handleDeleteusers()}
                  >
                    delete all
                  </StyledButton>
                ):(  
                  sortOptions.map((option) => (
                        <StyledButton
                        key={option.sortKey}
                        size="small"
                        onClick={() => handleSort(option.sortKey)}
                        className={
                          activeSortOption === option.sortKey ? "active" : ""
                        }
                        >
                        {option.label}
                        </StyledButton>
                    )))}
                      

                </Flex>
            </Box>
          </td>
      </tr>
      <tr style={{height:"20px"}}></tr>
      <tr>
          {checkbox && (
          <th></th>
          )}
          {cols.map((col) => (
          <th key={col.label} {...col.props}>
              {col.label}
          </th>
          ))}
      </tr>
    </>
    );
}
export default TableHeader