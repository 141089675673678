import { jwtDecode } from "jwt-decode";


export const decodeToken = (token) => {
  if (!token) return null;

  try {
    const decodedToken = jwtDecode(token);
    return decodedToken?.role; 
  } catch (error) {
    console.error("Failed to decode token:", error);
    return null;
  }
};
//Client
//Admin