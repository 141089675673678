import { useLocation, useNavigate } from "react-router-dom";
import LayoutMasked from "layout/LayoutMasked";
import LoginForm from "./LoginForm";
import { Flex } from "ui";
import SocialMediaLogin from "./SocialMediaLogin";

function Login() {
  // const [login, setLogin] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const paramsString = window.location.search;
  let searchParams = new URLSearchParams(paramsString);

  let from =
    location.state?.from?.pathname ||
    (searchParams.has("from") && searchParams.get("from")) ||
    "/dashboard";

  const handleSuccess = () => {
    navigate(from, { replace: true });
  };


  return (
    <LayoutMasked
      title="Login to form easy"
      subtitle="Welcome back ! we missed you."
    >
      <SocialMediaLogin />

      <Flex relative sx={{ ta: "center", mx: "auto", mb: 80,w:"100%" }}>
        <Flex dir="column" sx={{w:"100%"}}>
          <LoginForm onSuccess={handleSuccess} />
        </Flex>
      </Flex>
    </LayoutMasked>
  );
}

export default Login;
