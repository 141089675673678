const USER_KEY = "user";

export const userStorage = {
  getUser: () => {
    const localUser = localStorage.getItem(USER_KEY);
    const sessionUser = sessionStorage.getItem(USER_KEY);
    return JSON.parse(localUser || sessionUser || null);
  },

  setUser: (user, persist = true) => {
    const userString = JSON.stringify(user);
    if (persist) {
      localStorage.setItem(USER_KEY, userString);
    } else {
      sessionStorage.setItem(USER_KEY, userString);
    }
  },

  removeUser: () => {
    localStorage.removeItem(USER_KEY);
    sessionStorage.removeItem(USER_KEY);
  },
};
