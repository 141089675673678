import { useState } from "react";
import styled from "styled-components";
import { Box, Button, Flex, Select, Type } from "ui";

const StyledButton = styled(Button)`
  text-transform: uppercase;
  font-size: 12px;
  color: darkBlue;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 3px;
  &:hover,
  &:active ,
  &.active {
    color: white;
    background-color: darkBlue;
    svg path {
      fill: ${(props) => props.theme.color.white};
    }
    svg.export path {
      fill: ${(props) => props.theme.color.darkBlue};
      stroke: ${(props) => props.theme.color.white};
    }
  }
`;
const buttons = [
    {
      name: "Filter",
      iconName: "Filter",
      label: "Filters",
    },
    {
      name: "Sort",
      iconName: "Sort",
      label: "Sort",
    }
  ];
const sortOptions = [
  {
    label: "Data (Newest first)",
    sortKey: "newestFirst",
  },
  {
    label: "Data (Oldest first)",
    sortKey: "oldestFirst",
  },
];
function Header ({setOrder,usersList,setFilterByUser,filterByUser}) {
const [activeButton, setActiveButton] = useState('Sort');
const [activeSortButton, setActiveSortButton] = useState('');




const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    setActiveSortButton("")
    if(buttonName==="Sort")
      setFilterByUser("")
    else setOrder("")
  };

const handleSort = async (sortKey) => {
  setActiveSortButton(sortKey)
    if (sortKey === "newestFirst") {
      setOrder("desc");
    } else if (sortKey === "oldestFirst") {
      setOrder("asc");
    } 
};



    return(
      <>
        <Box sx={{ br: "4px" }}>
        <Flex
          jc="flex-end"
          ai="center"
          sx={{ p: "5px" }}
          style={{ backgroundColor: "#f2f3f9" }}
        >
         <Flex gap="5">
          {buttons.map((button) => (
            <StyledButton
              key={button.iconName}
              size="small"
              iconName={button.iconName}
              onClick={() => {
                handleButtonClick(button.name);
              }}
              className={activeButton === button.name ? "active" : ""}
            >
              {button.label}
            </StyledButton>
          ))}
        </Flex>
        </Flex>
        <Flex
          jc="flex-start"
          gap="5"
          sx={{ p: "5px",bgc:"green" }}
          style={{ backgroundColor: "#d7d9ed" ,minHeight:"45px"}}
        >
          { activeButton==='Sort' ? (
            <>
               {sortOptions.map((option) => (
                <StyledButton
                  key={option.sortKey}
                  size="small"
                  onClick={() => handleSort(option.sortKey)}
                  className={activeSortButton === option.sortKey ? "active" : ""}
                >
                  {option.label}
                </StyledButton>
              ))}
            </>
          ):activeButton==='Filter' ? (
              <Flex gap="5px"sx={{w:["30%","_","_","100%"]}}>
              <Type var="h6" sx={{minw:"fit-content"}}>by user :</Type>
              <Select
                sx={{minw:"fit-content"}}
                data={usersList}
                mapData={{ label: "fullName", value: "id" }}
                defaultValue="all"
                name="filter form by user"
                value={filterByUser}
                onChange={setFilterByUser}
                search={true}
                size="small"
              />
              </Flex>
          ):(<></>)}
        </Flex>
      </Box>
      </>
    );
}
export default Header