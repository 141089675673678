import Layout from "layout/Layout";
import { useEffect, useState } from "react";
import { Flex, Icon, Loading, TableContainer,Pagination } from "ui";
import { useAdmin } from "../provider";
import { useSubmissionForm } from "views/submitionForm/provider";

function Users () {
  const [list,setList] = useState([]);
  const [confirm,setConfirm] = useState({})
  const {
      state: { usersList,page,limit,users,status},
      FetchUsersList,
      deleteUser,
      dispatch
  } = useAdmin();
  const {
      fetchTotalForms
  } = useSubmissionForm();
  
    useEffect(() => {
      const getUsersList=async(e) => {  
        try {
        await FetchUsersList(page,limit);
        } catch (e) {
        console.log(e);
        }
      };
      getUsersList();
    
    }, [FetchUsersList,limit,page]);

    useEffect(() => {
      const fetchFormsForUsers = async () => {
        try {
          const updatedUsers = await Promise.all(
            usersList.map(async (user) => {
              try {
                const formsData = await fetchTotalForms(page, limit,"", user.id);
                return {
                  ...user,
                  fullName: `${user.firstName} ${user.lastName}`,
                  numberOfForms: formsData?.meta?.totalItems || 0, 
                };
              } catch (error) {
                console.error(`Error fetching forms for user ${user.id}:`, error);
                return {
                  ...user,
                  fullName: `${user.firstName} ${user.lastName}`,
                  numberOfForms: 0, 
                };
              }
            })
          );
          setList(updatedUsers);
        } catch (error) {
          console.error("Error updating users with forms data:", error);
        }
      };
  
      if (usersList.length > 0) {
        fetchFormsForUsers();
      }
    }, [usersList, fetchTotalForms, page, limit]);
    
    const DeleteUser = (id) => {
      const result = window.confirm("Are you sure you want to delete this user?");
      if (result) {
        setConfirm((prevConfirm) => ({
          ...prevConfirm,
          [id]: true,
        }));
        deleteUser({ids:[id]})
      }
    };
    const handleChangePage = (newPage) =>
      dispatch({ type: "set_page", payload: newPage });
  
    const columns = [
      {
        label: "Name",
        name: "fullName",
        style: { textAlign: "center", fontWeight: "600", fontSize: "12px" },
      },
      {
        label: "Email",
        name: "primaryVerifiedEmail",
        style: { textAlign: "center", fontWeight: "600", fontSize: "12px" },
  
      },
      {
        label: "Date",
        name: "createDate",
        style: { textAlign: "center", fontWeight: "600", fontSize: "12px" },
        
      },
      {
        label: "Number of form",
        name: "numberOfForms",
        style: { textAlign: "center", fontWeight: "600", fontSize: "12px" },
    
      },
      {
        label: "Action",
        name: "Action",
        render: ({ id }) =>
          <Flex ai="center" jc="center">
            <Flex 
            sx={{
            w:"35px",
            h:"35px",
            br:"50%",
            }}
            style={{
            backgroundColor:"#f7eaea",
            cursor:"pointer"
            }}
            onClick={()=>DeleteUser(id)}>
            {!confirm[id] ? (
            <Icon name="trash2" w="16" c="red" />
          ) : (
            <Loading size={18} />
          )}
          
            </Flex>
          </Flex>
      },
    ];
    
    return (
        <Layout admin>
            <TableContainer 
            status={status}
            data={list}  
            cols={columns}
            checkbox
            mixedHeader
            />
            <Pagination
            count={users?.count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={limit}
            />
        </Layout>
    );
}
export default Users