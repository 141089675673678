import { useCallback, useEffect, useState } from "react";
import { InputRadio, InputText, Type } from "ui";
import {
  AlignedButton,
  FlexStyled,
  SecondRowItem,
  StyledBox,
} from "Components";
import { useAdmin } from "../provider";
import { useUtils } from "services/utilsProvider";
import FileMimeType from "./FileTypes";

function Content() {
  const {
    state: { settings },
    FetchSettings,
    EditSettings,
  } = useAdmin();
  const [enableFileUpload, setEnableFileUpload] = useState(
    settings?.items?.enableFileUpload
  );
  const [enableApi, setenableApi] = useState(
    settings?.items?.enableProgrammaticAccess
  );
  const [msg,setMsg] = useState ('')
  const [allowedFileTypes, setAllowedFileTypes] = useState((settings?.items?.allowedFileTypes?.map((mimeType) => FileMimeType[mimeType]))?.join(","));
  const [maxSingleFileSize, setMaxSingleFileSize] = useState(settings?.items?.maxSingleFileSize);
  const [maxFilesPerSubmission, setMaxFilesPerSubmission] = useState(settings?.items?.maxFilesPerSubmission);
  const [maxUploadSizePerUserPerMonth, setMaxUploadSizePerUserPerMonth] = useState(settings?.items?.maxUploadSizePerUserPerMonth);
  const [maxFormsPerUserPerMonth, setMaxFormsPerUserPerMonth] = useState(settings?.items?.maxFormsPerUserPerMonth);
  const [maxSubmissionsPerUserPerMonth, setMaxSubmissionsPerUserPerMonth] = useState(settings?.items?.maxSubmissionsPerUserPerMonth);
  const [maxExportsPerUserPerMonth, setMaxExportsPerUserPerMonth] = useState(settings?.items?.maxSubmissionsPerUserPerMonth);
//ERROR VAR
  const [allowedFileTypesError, setAllowedFileTypesError] = useState(false);
  const [maxSingleFileSizeError, setMaxSingleFileSizeError] = useState(false);
  const [maxFilesPerSubmissionError, setMaxFilesPerSubmissionError] = useState(false);
  const [maxUploadSizePerUserPerMonthError, setMaxUploadSizePerUserPerMonthError] = useState(false);
  const [maxFormsPerUserPerMonthError, setMaxFormsPerUserPerMonthError] = useState(false);
  const [maxSubmissionsPerUserPerMonthError, setMaxSubmissionsPerUserPerMonthError] = useState(false);
  const [maxExportsPerUserPerMonthError, setMaxExportsPerUserPerMonthError] = useState(false);
  const { notify } = useUtils();


  useEffect(() => {
    const getSettings=async(e) => {  
      try {
      await  FetchSettings();
      } catch (e) {
      console.log(e);
      }
    };
    getSettings();
  }, [FetchSettings]);
  
  useEffect(() => {
    if (settings && settings.items) {
      setEnableFileUpload(settings.items.enableFileUpload);
      setenableApi(settings.items.enableProgrammaticAccess);
      setAllowedFileTypes((settings.items.allowedFileTypes?.map((mimeType) => FileMimeType[mimeType]))?.join(","));
      setMaxSingleFileSize(settings?.items?.maxSingleFileSize);
      setMaxFilesPerSubmission(settings?.items?.maxFilesPerSubmission);
      setMaxUploadSizePerUserPerMonth(settings?.items?.maxUploadSizePerUserPerMonth);
      setMaxFormsPerUserPerMonth(settings?.items?.maxFormsPerUserPerMonth);
      setMaxSubmissionsPerUserPerMonth(settings?.items?.maxSubmissionsPerUserPerMonth);
      setMaxExportsPerUserPerMonth(settings?.items?.maxExportsPerUserPerMonth);
    }
  }, [settings]);

  const handleAllowedFileTypes = useCallback((event) => {
    setAllowedFileTypes(event.target.value);
  }, []);
  const handleMaxSingleFileSize = useCallback((event) => {
    setMaxSingleFileSize(event.target.value);
  }, []);
  const handleMaxFilesPerSubmission = useCallback((event) => {
    setMaxFilesPerSubmission(event.target.value);
  }, []);
  const handleMaxUploadSizePerUserPerMonth = useCallback((event) => {
    setMaxUploadSizePerUserPerMonth(event.target.value);
  }, []);
  const handleMaxFormsPerUserPerMonth = useCallback((event) => {
    setMaxFormsPerUserPerMonth(event.target.value);
  }, []);
  const handleMaxSubmissionsPerUserPerMonth = useCallback((event) => {
    setMaxSubmissionsPerUserPerMonth(event.target.value);
  }, []);
  const handleMaxExportsPerUserPerMonth = useCallback((event) => {
    setMaxExportsPerUserPerMonth(event.target.value);
  }, []);
  const validateNumber = (value) => {
    if (!/^\d+$/.test(value)) {
      return false; // Not a valid number
    }
  
    const fileSize = parseInt(value);
    if (!Number.isInteger(fileSize)) {
      return false; // Not an integer
    }
  
    return fileSize > 0 && fileSize <= 9007199254740991;
  };
  const validateMaxFileSize  =(value) => {
    if (!validateNumber(value)){
      setMaxSingleFileSizeError(true)
    }else{
      setMaxSingleFileSizeError(false);
      return parseInt(value)
    }
  }
  const validateMaxFilesPerSubmission  =(value) => {
    if (!validateNumber(value)){
      setMaxFilesPerSubmissionError(true)
    }else{
      setMaxFilesPerSubmissionError(false);
      return parseInt(value)
    }
  }
  const validateMaxUploadSizePerUserPerMonth  =(value) => {
    if (!validateNumber(value)){
      setMaxUploadSizePerUserPerMonthError(true)
    }
    else{
      setMaxUploadSizePerUserPerMonthError(false);
      return parseInt(value)
    }
    
  }
  const validateMaxFormsPerUserPerMonth  =(value) => {
    if (!validateNumber(value)){
      setMaxFormsPerUserPerMonthError(true)
    }
    else{
      setMaxFormsPerUserPerMonthError(false);
      return parseInt(value)
    }
    
  }
  const validateMaxSubmissionsPerUserPerMonth  =(value) => {
    if (!validateNumber(value)){
      setMaxSubmissionsPerUserPerMonthError(true)
    }
    else{
      setMaxSubmissionsPerUserPerMonthError(false);
      return parseInt(value)
    }
    
  }
  const validateMaxExportsPerUserPerMonth  =(value) => {
    if (!validateNumber(value)){
      setMaxExportsPerUserPerMonthError(true)
    }
    else{
      setMaxExportsPerUserPerMonthError(false);
      return parseInt(value)
    }
    
  }
  const convertFileTypesToMimeTypes = (fileTypes) => {
    const fileTypesArray = fileTypes?.split(",").map(type => type.trim());
    const mimeTypesArray = fileTypesArray?.map(extension  => {
      const mimeType = Object.keys(FileMimeType)?.find(key => FileMimeType[key] === extension );
      if (!mimeType) {
        setMsg(`Invalid file type: ${extension}`)
        setAllowedFileTypesError(true)
      }else{
        setAllowedFileTypesError(false)
      }
      return mimeType;
    });
    return mimeTypesArray;

  };
  const handleEdit = (e) => {
    setAllowedFileTypesError(false);
    setMaxSingleFileSizeError(false);
    setMaxFilesPerSubmissionError(false);
    setMaxUploadSizePerUserPerMonthError(false);
    setMaxFormsPerUserPerMonthError(false);
    setMaxSubmissionsPerUserPerMonthError(false);
    setMaxExportsPerUserPerMonthError(false);
    e.preventDefault();
      const data = {
        enableFileUpload: enableFileUpload,
        enableProgrammaticAccess: enableApi,
        allowedFileTypes:convertFileTypesToMimeTypes(allowedFileTypes),
        maxSingleFileSize :validateMaxFileSize(maxSingleFileSize),
        maxFilesPerSubmission : validateMaxFilesPerSubmission(maxFilesPerSubmission),
        maxUploadSizePerUserPerMonth : validateMaxUploadSizePerUserPerMonth(maxUploadSizePerUserPerMonth),
        maxFormsPerUserPerMonth : validateMaxFormsPerUserPerMonth(maxFormsPerUserPerMonth),
        maxSubmissionsPerUserPerMonth : validateMaxSubmissionsPerUserPerMonth(maxSubmissionsPerUserPerMonth),
        maxExportsPerUserPerMonth : validateMaxExportsPerUserPerMonth(maxExportsPerUserPerMonth)
      };
      if (!data.allowedFileTypes?.includes(undefined) 
        &&validateNumber(maxSingleFileSize)
        &&validateNumber(maxFilesPerSubmission)
        &&validateNumber(maxUploadSizePerUserPerMonth)
        &&validateNumber(maxFormsPerUserPerMonth)
        &&validateNumber(maxSubmissionsPerUserPerMonth)
        &&validateNumber(maxExportsPerUserPerMonth)){
        edit(data)
      } else {
        notify(`cannot edit`, "info");
      }

  
  
  };
  const edit = async (data) => {
    await EditSettings(data);
    notify(`settings edited`, "success");
    
  };

  return (
    <>
      <FlexStyled style={{ justifyContent: "flex-start" }}>
        <InputRadio
          toggleButton={true}
          checked={enableFileUpload}
          inputProps={{
            checked: enableFileUpload,
            onChange: () => setEnableFileUpload(!enableFileUpload),
          }}
        />
        <StyledBox >
          <Type>Enable file upload</Type>
          <Type variant="subtitle">
            Change the enctype in the form to allow files to be uploaded
          </Type>
        </StyledBox>
      </FlexStyled>

      <FlexStyled>
        <span />
        <StyledBox >
          <Type>File types</Type>
          <Type variant="subtitle">Enter all allowed file types</Type>
        </StyledBox>
        <SecondRowItem>
          <InputText
            placeholder=" jpg, png, txt."
            size="medium"
            error={allowedFileTypesError}
            msg= {allowedFileTypesError ? msg : " Please enter all allowed file types separated by commas (e.g., jpg, png, txt)"}
            sx={{
              w: ["300px","_","_","250px"],
              mb: "0px",
            }}
            value={allowedFileTypes}
            onChange={handleAllowedFileTypes}
          />
        </SecondRowItem>
      </FlexStyled>

      <FlexStyled>
        <span />
        <StyledBox >
          <Type>Max File Size</Type>
          <Type variant="subtitle">Enter all allowed file sizes</Type>
        </StyledBox>
        <SecondRowItem>
          <InputText
            placeholder="max file size"
            size="medium"
            sx={{
              w: ["300px","_","_","250px"],
              mb: "0px",
            }}
            error={maxSingleFileSizeError}
            msg={
              maxSingleFileSizeError
                ? 'Invalid'
                :''
            }
            value={maxSingleFileSize}
            onChange={handleMaxSingleFileSize}
            
          />
        </SecondRowItem>
      </FlexStyled>

      <FlexStyled>
        <span />
        <StyledBox >
          <Type>Max Files Per Submission</Type>
        </StyledBox>
        <SecondRowItem>
          <InputText
            placeholder="Max Files Per Submission"
            size="medium"
            sx={{
              w: ["300px","_","_","250px"],
              mb: "0px",
            }}
            error={maxFilesPerSubmissionError}
            msg={
              maxFilesPerSubmissionError
                ? 'Invalid'
                :''
            }
            value={maxFilesPerSubmission}
            onChange={handleMaxFilesPerSubmission}
            
          />
        </SecondRowItem>
      </FlexStyled>

      <FlexStyled>
        <span />
        <StyledBox >
          <Type>Max Upload Size Per User Per Month</Type>
        </StyledBox>
        <SecondRowItem>
          <InputText
            placeholder="Max Upload Size"
            size="medium"
            sx={{
              w: ["300px","_","_","250px"],
              mb: "0px",
            }}
            error={maxUploadSizePerUserPerMonthError}
            msg={
              maxUploadSizePerUserPerMonthError
                ? 'Invalid'
                :''
            }
            value={maxUploadSizePerUserPerMonth}
            onChange={handleMaxUploadSizePerUserPerMonth}
            
          />
        </SecondRowItem>
      </FlexStyled>

      <FlexStyled>
        <span />
        <StyledBox >
          <Type>Max Forms Per User Per Month</Type>
        </StyledBox>
        <SecondRowItem>
          <InputText
            placeholder="Max Forms"
            size="medium"
            sx={{
              w: ["300px","_","_","250px"],
              mb: "0px",
            }}
            error={maxFormsPerUserPerMonthError}
            msg={
              maxFormsPerUserPerMonthError
                ? 'Invalid'
                :''
            }
            value={maxFormsPerUserPerMonth}
            onChange={handleMaxFormsPerUserPerMonth}
            
          />
        </SecondRowItem>
      </FlexStyled>

      <FlexStyled>
        <span />
        <StyledBox >
          <Type>Max Submissions Per User Per Month</Type>
        </StyledBox>
        <SecondRowItem>
          <InputText
            placeholder="Max Submissions"
            size="medium"
            sx={{
              w: ["300px","_","_","250px"],
              mb: "0px",
            }}
            error={maxSubmissionsPerUserPerMonthError}
            msg={
              maxSubmissionsPerUserPerMonthError
                ? 'Invalid'
                :''
            }
            value={maxSubmissionsPerUserPerMonth}
            onChange={handleMaxSubmissionsPerUserPerMonth}
            
          />
        </SecondRowItem>
      </FlexStyled>

      <FlexStyled>
        <span />
        <StyledBox >
          <Type>Max Exports Per User Per Month</Type>
        </StyledBox>
        <SecondRowItem>
          <InputText
            placeholder="Max Exports Per User Per Month"
            size="medium"
            sx={{
              w: ["300px","_","_","250px"],
              mb: "0px",
            }}
            error={maxExportsPerUserPerMonthError}
            msg={
              maxExportsPerUserPerMonthError
                ? 'Invalid'
                :''
            }
            value={maxExportsPerUserPerMonth}
            onChange={handleMaxExportsPerUserPerMonth}
            
          />
        </SecondRowItem>
      </FlexStyled>

      <FlexStyled style={{ justifyContent: "flex-start" }}>
        <InputRadio
          toggleButton={true}
          checked={enableApi}
          inputProps={{
            checked: enableApi,
            onChange: () => setenableApi(!enableApi),
          }}
        />
        <StyledBox >
          <Type>Enable api</Type>
          <Type variant="subtitle">
            Change the enctype in the form to allow files to be uploaded
          </Type>
        </StyledBox>
      </FlexStyled>

      <AlignedButton onClick={(e) => handleEdit(e)}>save</AlignedButton>
    </>
  );
}
export default Content;
