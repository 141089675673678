import { useState } from "react";
import { Box, Flex, Pagination, Slider, Type } from "ui";
import DeleteFormDialog from "views/submitionForm/formList/DeleteFormDialog";
import FormCard from "views/submitionForm/formList/FormCard";

function FormsList ({formsList,page,limit,handleChangePage}) {
const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
const [projectId, setProjectId] = useState();

  
    return(
      <>
        <Box w="100%" sx={{ p: "20px" }}>
        {formsList?.resData?.items?.length !== 0 ?(
          <>
            <Slider
              slides={formsList?.resData?.items?.map((form, i) => (
                <FormCard
                  hideBtn={true}
                  id={"card-" + i}
                  key={form.id}
                  {...{
                    setOpenDeleteDialog,
                    form,
                    projectId: form?.projectId,
                    setProjectId,
                  }}
                />
              ))}
              totalView={3}
            />
          <Pagination
          count={formsList?.resData?.meta?.totalItems}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={limit}
          />
          </>
        ):(
          <Flex sx={{ py: 60 }}>
          <Type var="subtitle" sx={{ c: "gray600", fs: 14 }}>
            There is no forms yet!
          </Type>
        </Flex>
        )
        }
      </Box>
       <DeleteFormDialog
              {...{
                setOpenDeleteDialog,
                openDeleteDialog,
                projectId: projectId,
              }}
             /> </>
    )
}
export default FormsList