import Layout from "layout/Layout"
import { Box, Flex, Icon, Loading, Type } from "ui"
import SubmissionAnalytics from "./SubmissionAnalytics";
import { useEffect } from "react";
import { useAdmin } from "./provider";
import { useSubmissionForm } from "views/submitionForm/provider";

function AdminDashboard (){
    const {
        state: { users ,status:adminStatus},
        FetchUsersCount,
      } = useAdmin();
    const {
        state: { totalForms,page,limit ,status:formStatus},
        fetchTotalForms
    } = useSubmissionForm();

    useEffect(() => {
    const getTotalForms=async(e) => {  
        try {
        await fetchTotalForms(page,limit);
        } catch (e) {
        console.log(e);
        }
    };
    getTotalForms();

    }, [fetchTotalForms,limit,page]);

    useEffect(()=>{
        const getUsersCount=async(e) => {  
            try {
            await FetchUsersCount();
            } catch (e) {
            console.log(e);
            }
          };
        getUsersCount();
    },[FetchUsersCount])


    const boxData = [
        {
          label: 'Total User',
          value: users?.count,
          icon :"User2",
        },
        {
          label: 'Total Form',
          value: totalForms?.resData?.meta?.totalItems,
          icon :"File2",
        },
      ];
    return(
        <Layout admin>
            <>
            {adminStatus === "fetching"||formStatus ==="fetching"?
            (
            <Flex>
                <Loading/>
            </Flex>
            ):
           <>
            <Flex
            gap={30}
            jc="space-between"
            sx={{
                w:"100%",
                dir :["_","_","_","column"],
            }}>
                
                {boxData.map((data, index) => (
                <Box
                key={index}
                sx={{
                    w:["40%","_","_","100%"],
                    p:"10px",
                    br:"6px",
                    border: ["gray300", 1, "solid", 6, "tlrb"],
                }}>
                    <Flex
                    jc="space-between"
                    sx={{
                        mb:"10px",
                        ff:"secondary",
                    }}>
                        <Box>
                            <Type
                            var="p"
                            sx = {{
                                c:"gray500"
                            }}
                            >
                                {data.label}
                            </Type>
                            <Type 
                            var='h6'
                            >
                                {data.value}
                            </Type>
                        </Box>
                        <Flex
                        sx={{
                            bgc:"darkBlue",
                            p:"5px",
                            br:"10px"
                        }}>
                            <Icon 
                            name={data.icon}
                            w="20"
                            />
                        </Flex>
                    </Flex>
                </Box>
                ))}

            </Flex>
            <SubmissionAnalytics/>
            </>
            }
            </>
        </Layout>
    )

}
export default AdminDashboard