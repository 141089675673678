import Layout from "layout/Layout";
import Header from "./Header";
import FormsList from "./FormsList";
import { useEffect, useState } from "react";
import { useSubmissionForm } from "views/submitionForm/provider";
import { Flex, Loading } from "ui";
import { useAdmin } from "../provider";


function Forms (){
    const {
        state: { totalForms,page,limit ,status:formStatus},
        fetchTotalForms,
        dispatch
    } = useSubmissionForm();
     const {
          state: { usersList ,users},
          FetchUsersList,
          FetchUsersCount
      } = useAdmin();
    const [order,setOrder] = useState("");
    const [filterByUser,setFilterByUser] = useState("");
    console.log(filterByUser);

    const usersWithFullName = usersList?.map(user => ({
        ...user,
        fullName: `${user.firstName} ${user.lastName}`,
    }));

    const handleChangePage = (newPage) =>
        dispatch({ type: "set_page", payload: newPage });

    useEffect(() => {
        const getTotalForms=async(e) => {  
            try {
            await fetchTotalForms(page,limit,order,filterByUser?.value);
            } catch (e) {
            console.log(e);
            }
        };
        getTotalForms();
    
        }, [fetchTotalForms,limit,page,order,filterByUser]);
    useEffect(()=>{
        const getUsersCount=async(e) => {  
            try {
            await FetchUsersCount();
            } catch (e) {
            console.log(e);
            }
            };
        getUsersCount();
    },[FetchUsersCount])

    useEffect(() => {
        const getUsersList=async(e) => {  
            try {
            await FetchUsersList(0,users?.count);
            } catch (e) {
            console.log(e);
            }
        };
    getUsersList();
    
    }, [FetchUsersList,users]);
    

    return (
        <Layout admin>
            <Header 
            setOrder={setOrder} 
            usersList={usersWithFullName} 
            filterByUser={filterByUser} 
            setFilterByUser={setFilterByUser}
            />
            {formStatus==="fetching"?(
                <Flex>
                    <Loading/>
                </Flex>
            ):(
                <FormsList 
                formsList ={totalForms} 
                page={page}
                limit={limit} 
                handleChangePage={handleChangePage}
                />
            )}

        </Layout>
    );
}
export default Forms 