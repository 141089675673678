import LayoutMasked from "layout/LayoutMasked";
import {useNavigate } from "react-router-dom";
import { Button } from "ui";
const NoAccess = () => {
let navigate = useNavigate();
  return (
    <LayoutMasked
    title="You do not have permission to access this page."
    >
        <Button sx={{mt:"10px"}}  onClick={() => navigate("/login")}>
            Go To Login Page
        </Button>
    </LayoutMasked>
  );
};

export default NoAccess;
