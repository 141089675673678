import styled from "styled-components";
import { useAuth } from "services/auth";
import { Box, Flex, Icon, Link, Loading, Type } from "ui";
import Avatar from "ui/Avatar";
import Card from "ui/Card";
import Dropdown from "ui/core/Dropdown";
import { getInitials } from "utilities/functions";
import { useProfile } from "views/Account/provider";
import theme from "theme";
import { userStorage } from "services/userStorage";
// import "./header.css";

const List = styled.ul`
  list-style: none;
  padding: 5px 0;
  margin: 10px 0;
  li {
    a {
      padding: 15px 20px;
      font-size: 14px;
      color: ${theme.color.gray600};
      text-decoration: none;
      font-weight: 600;
      &:hover {
        background-color: ${theme.color.gray200};
      }
    }
  }
`;

function UserInfo() {
  const {
    state: { selected: profile },
  } = useProfile();

  return (
    <Box sx={{ bgc: "primaryBg", px: 20, py: 15 }}>
      <Type var="h6">
        {profile["firstName"]} {profile["lastName"]}
      </Type>
      <Type>{profile["primaryVerifiedEmail"]}</Type>
    </Box>
  );
}

function ProfileOpts() {
  const { signout } = useAuth();
    const storedUser = userStorage.getUser();

  const userNav = [
    { label: "Account", path: "/account", icon: "user" },
    { label: "API Keys", path: "/api", icon: "code" },
    { label: "Sign Out", click: signout, icon: "logout" },
  ];
  const adminNav = [
    { label: "Sign Out", click: signout, icon: "logout" },
  ];
  const Nav = storedUser?.role==="Admin"?adminNav:userNav

  return (
    <Card sx={{ w: 220, br: 8, of: "hidden", mr: 53 }} padding="0" shadow>
      <UserInfo />
      <List>
        {Nav.map((nav, i) => {
          const p = {};
          if (nav.click) p.onClick = nav.click;
          if (nav.path) p.to = nav.path;

          return (
            <li key={i}>
              <Flex jc="flex-start" gap={12} as={Link} {...p}>
                <Icon name={nav.icon} w={16} c="gray600" /> {nav.label}
              </Flex>
            </li>
          );
        })}
      </List>
    </Card>
  );
}

export default function UserAccount() {
  const {
    state: { selected: profile },
  } = useProfile();

  return !profile ? (
    <Loading />
  ) : (
    <Dropdown space={-7} content={<ProfileOpts />} animateFrom="top right">
      <Avatar
        role="button"
        aria-label="current user account"
        var="dark"
        size={38}
        sx={{
          cursor: "pointer",
          bc: "gray400",
          bw: 1,
          fw: 600,
          c: "primary",
        }}
      >
        {getInitials(profile["firstName"] + " " + profile["lastName"])}
      </Avatar>
    </Dropdown>
  );
}
